<template>
  <div id="app">
    <b-container>
      <b-form>
        <b-row>
          <b-col class="my-3">
            <label>Survey ID</label>
            <div class="input-group">
              <b-form-input
                  v-model="survey_config.id"
                  placeholder="ID"
                  required
              ></b-form-input>
              <b-button @click="survey_config.id = Date.now().toString()" variant="outline-primary" class="btn-sm"><b-icon-gear/> Generate</b-button>
            </div>

            </b-col>
          <b-col class="my-3">
            <label>Segment</label>
            <b-form-input
                v-model="survey_config.seg"
                placeholder="Segment"
                required
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-for="(question, index) in survey_config.questions" :key="index" md="6" class="p-2">
            <div class="px-3 py-2 border rounded-3"><h5 class="mt-3">Question ID: {{ question.id }}</h5>
              <label>Question:</label>
              <b-form-input v-model="question.text"></b-form-input>
              <div class="mt-2">
                <label>Type:</label>
                <v-select v-model="question.type" :clearable="false"
                          :options="['SINGLE_OPTION', 'MULTI_OPTION', 'ORDER']"/>
              </div>
              <h5 class="mt-3">Options:</h5>
              <b-row>
                <b-col v-for="(option, i) in question.options" :key="i" cols="12">
                  <b-row>
                    <b-col cols="9"><label>Option {{ option.id }}: </label>
                      <b-form-input v-model="option.text"></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <label>Next: </label>
                      <b-form-input v-model="question.next_question[option.id]"></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="my-3">
                  <b-button class="me-2" size="sm" variant="primary"
                            @click="question.options.push({id: String.fromCharCode(question.options[question.options.length - 1].id.charCodeAt() + 1), role: 'option', text: ''})">
                    <b-icon-plus/>
                    Add Option
                  </b-button>
                  <b-button size="sm" variant="danger" :disabled="question.options.length < 2" @click="question.options.pop()">
                    <b-icon-trash/>
                    Remove Option
                  </b-button>
                </b-col>
              </b-row></div>
          </b-col>
          <b-col class="my-3 d-flex" cols="12">
            <b-button class="me-2" variant="primary" @click="survey_config.questions.push({
            id: survey_config.questions.length+1,
            type: 'SINGLE_OPTION',
            text: 'New Question',
            options: [{id: 'A', role: 'option', text: 'Option A'}],
            next_question: {A: 'end'}
            })">
              <b-icon-plus/>
              Add Question
            </b-button>
            <b-button variant="danger" :disabled="survey_config.questions.length < 2" @click="survey_config.questions.pop()">
              <b-icon-trash/>
              Remove Question
            </b-button>
            <div class="flex-grow-1 justify-content-end d-inline-flex">
              <b-button
                  @click="downLoad()" variant="success"
              >
                <b-icon-download/>
                Download Creative
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <hr>
      <h5>To edit previously generated creative, upload the config.js file</h5>
      <b-form-file class="form-control mb-5"
          @input="fileUpload"
          v-model="uploadFile"
          :state="Boolean(uploadFile)"
          placeholder="Choose a config.js file or drop it here..."
          drop-placeholder="Drop config.js file here..."
          accept=".js" plain
      ></b-form-file>
    </b-container>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import JSZip from "jszip";
import FileSaver from "file-saver";
import axios from "axios";

const getFile = url => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "ArrayBuffer"
    })
        .then(data => {
          resolve(data.data);
        })
        .catch(error => {
          reject(error.toString());
        });
  });
};


export default {
  name: 'Brandometer_Config_Generator',
  data() {
    return {
      uploadFile: null,
      survey_config: {
        id: "5733933299793920",
        seg: "F18-24",
        carousel_images: [],
        thankyou_text: "Thank You",
        questions: [{
          id: "1",
          type: "MULTI_OPTION",
          text: "Which of the following Short Video Apps have you heard of?",
          options: [{id: "A", role: "option", text: "MX Takatak"}, {id: "B", role: "option", text: "Moj"}, {
            id: "C",
            role: "option",
            text: "Snack Video"
          }, {id: "D", role: "option", text: "Josh"}, {id: "E", role: "reset", text: "None of the above"}],
          next_question: {A: "2", B: "2", C: "2", D: "2", E: "2"}
        }, {
          id: "2",
          type: "SINGLE_OPTION",
          text: "Have you seen Badshah in MX TakaTak ads recently?",
          options: [{id: "A", role: "option", text: "Yes"}, {id: "B", role: "option", text: "No"}],
          next_question: {A: "3", B: "end"}
        }, {
          id: "3",
          type: "MULTI_OPTION",
          text: "On which platform have you seen the ad?",
          options: [{id: "A", role: "option", text: "YouTube"}, {id: "B", role: "option", text: "Facebook"}, {
            id: "C",
            role: "option",
            text: "Hotstar"
          }, {id: "D", role: "option", text: "Other"}],
          next_question: {A: "4", B: "4", C: "4", D: "4"}
        }, {
          id: "4",
          type: "SINGLE_OPTION",
          text: "Which of the following Short Video Apps, uses the message &ldquo;Kar TakaTak bann TakaTak&rdquo; in its advertising?",
          options: [{id: "A", role: "option", text: "MX Takatak"}, {id: "B", role: "option", text: "Moj"}, {
            id: "C",
            role: "option",
            text: "Snack Video"
          }, {id: "D", role: "option", text: "Josh"}, {id: "E", role: "reset", text: "None of the above"}],
          next_question: {A: "end", B: "end", C: "end", D: "end", E: "end"}
        }],
        exit_url: "https://www.google.com",
        response_server: "https://agnivo-brandometer.appspot.com/measure?type=survey&id={survey_id}&seg={seg}&response={responses}&visual={visual_responses}&creative_size={size}&randomtimestamp={random_timestamp}&bomid={bomid}&times={time_measurement}",
        trackers: [""]
      },
      ui_config: {
        creative_size: {width: 300, height: 250},
        background_color: "#008000",
        carousel_config: {},
        component_selectors: {
          next_button: {container: "next_button", text: "Next"},
          thankyou: {container: "thankyou_container", text: "thankyou_text"},
          survey: {
            container: "survey_container",
            question: {text: "question_text", container: "question_container"},
            options: {
              max_options: 5,
              option_prefix: {text: "option_text_", container: "option_container_", label: "option_label_"}
            }
          },
          comment: {container: "question_comment", text: "Choose all applicable"}
        }
      }
    }
  },
  computed: {
    output: {
      get(){
        return `var fullConfiguration =  ${JSON.stringify({
          survey_config: this.survey_config,
          ui_config: this.ui_config
        }, null, 2)}`
      },
      // set(val) {
      //   const input = JSON.parse(val.split("var fullConfiguration =")[1])
      //   this.survey_config = input.survey_config
      //   this.ui_config = input.ui_config
      // }
    }
  },
  methods: {
    fileUpload(){
      // if(!this.uploadFile) return;
      let read = new FileReader();
      read.readAsText(this.uploadFile);
      let that = this
      read.onload= function () {
        const input = JSON.parse(read.result.split("var fullConfiguration =")[1])
        that.survey_config = input.survey_config
        that.ui_config = input.ui_config
      }
    },
    downLoad() {
      const data = ["/creative/controller.js","/creative/index.html","/creative/main.js", "/creative/master.css"]; // The path to download and package, which can be a local relative path or a cross-domain full path
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      data.forEach(item => {
        const promise = getFile(item).then(data => {
          // Download the file and save it as an ArrayBuffer object
          const arr_name = item.split("/");
          const file_name = arr_name[arr_name.length-1]; // get the file name
          zip.file(file_name, data, {binary: true }); // add files one by one
          cache[file_name] = data;
        });
        promises.push(promise);
      });
      zip.file("config.js", this.output);
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then(content => {
          // Generate a binary stream
          FileSaver.saveAs(content, `Brandometer_${this.survey_config.id}_${this.survey_config.seg}.zip`); // Save the file with file-saver Custom file name
        });
      });
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

label {
  margin-bottom: 0.1em;
  margin-top: 0.2em;
}
</style>
